.container {
  display: flex;
  gap: 0.5rem;
}

.messageDateContainer {
  display: "flex";
  flex-direction: "column";
}

.truncate {
  /* https://albertwalicki.com/learn/solutions/how-to-limit-text-to-n-lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-block: 0;
  font-size: 1.125rem;
}

.link {
  color: inherit;
  font-size: inherit;
}

.whiteText {
  color: #ffffff;
}
