.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.container {
  display: flex;
  gap: 1em;
  padding: 1em;
  margin-block-end: 1rem;
  /* https://getcssscan.com/css-box-shadow-examples */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.content {
  flex: 1;
}

.container:hover {
  /* match ITEM_HEIGLIGHTED_COLOR defined on src\utils\theme\constrains.js */
  background-color: #e8eaf5;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleContainer .timeAgo {
  margin-left: auto;
}

.titleContainer .title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}

.message a {
  color: inherit;
}
