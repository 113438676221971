.center {
  text-align: center;
}

.notificationPanelFadeInDown {
  -webkit-animation-name: animateFadeIn;
  animation-name: animateFadeIn;
  animation-duration: 300s;
}

@keyframes animateFadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.notificationPanelFadeOutUp {
  -webkit-animation-name: animateFadeOut;
  animation-name: animateFadeOut;
  animation-duration: 300s;
}

@keyframes animateFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
}
